<template>
  <div class="mb-1">
    <h3>Adicione suas atividades</h3>
    <small class="text-muted">Preencha abaixo as informações da sua atividade, quando terminar, clique em finalizar</small>
    <hr>
    <!-- Form Activity -->
    <b-row class="mx-0">
      <b-col  lg="8" sm="6">
        <!-- Description -->
        <!--<b-form-group
          label="Titulo da atividade:"
          label-for="i-pergunta"
        >
          <b-form-input
            id="i-pergunta"
            placeholder="Atividade principal"
            v-model="activities.description"
          />
        </b-form-group> -->
        <!-- Lesson Id -->
        <b-form-group
          label="Selecione a aula:"
          label-for="i-aula"
        >
          <b-form-select
            text-field="name"
            value-field="id"
            v-model="activities.lesson_id"
            :options="lessonSelectLoading.lessons"
            id="module"
          />
        </b-form-group>
        <!-- Phases Id -->
        <b-form-group
          label="Selecione a fase da espiral:"
          label-for="i-phase"
        >
          <b-form-select
            text-field="name"
            value-field="id"
            v-model="activities.phase_id"
            :options="phases"
            id="module"
          />
        </b-form-group>

        <b-form-group
          label="Duração da atividade (Min):"
          label-for="i-time"
        >
          <b-form-input
            id="i-time"
            placeholder="30"
            v-model="activities.duration"
          />
        </b-form-group>
        <b-form-group
          label="Descrição:"
          label-for="i-geral"
        >
          <input type="file" @change="uploadFunction" id="file" hidden />
          <b-overlay
            :show="isLoadingQuill"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <quill-editor
              v-model="activities.description"
              :options="editorOption"
              @change="quillIndex('1')"
              ref="quillEdit1"
            >
            </quill-editor>
          </b-overlay>
        </b-form-group>
        <!--
        <b-form-group
          label="Objetivo pedagógicos:"
          label-for="i-obj-ped"
        >
          <b-overlay
            :show="isLoadingQuill"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <quill-editor
              v-model="activities.pedagogical_objectives"
              :options="editorOption"
              @change="quillIndex('2')"
              ref="quillEdit2"
            >
            </quill-editor>
          </b-overlay>
        </b-form-group>

        <b-form-group
          label="Materiais necessários:"
          label-for="i-meterial"
        >
          <b-overlay
            :show="isLoadingQuill"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <quill-editor
              v-model="activities.necessary_materials"
              :options="editorOption"
              @change="quillIndex('3')"
              ref="quillEdit3"
            >
            </quill-editor>
          </b-overlay>
        </b-form-group>

        <b-form-group
          label="Preparando a atividade:"
          label-for="i-atv"
        >
          <b-overlay
            :show="isLoadingQuill"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <quill-editor
              v-model="activities.preparing_the_activity"
              :options="editorOption"
              @change="quillIndex('4')"
              ref="quillEdit4"
            >
            </quill-editor>
          </b-overlay>
        </b-form-group>

        <b-form-group
          label="Introdução:"
          label-for="i-intro"
        >
          <b-overlay
            :show="isLoadingQuill"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <quill-editor
              v-model="activities.introduction"
              :options="editorOption"
              @change="quillIndex('5')"
              ref="quillEdit5"
            >
            </quill-editor>
          </b-overlay>
        </b-form-group>

        <b-form-group
          label="Desenvolvimento:"
          label-for="i-desenvolvimento"
        >
          <b-overlay
            :show="isLoadingQuill"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <quill-editor
              v-model="activities.development"
              :options="editorOption"
              @change="quillIndex('6')"
              ref="quillEdit6"
            >
            </quill-editor>
          </b-overlay>
        </b-form-group>

        <b-form-group
          label="Informações adicionais:"
          label-for="i-info-adc"
        >
          <b-overlay
            :show="isLoadingQuill"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <quill-editor
              v-model="activities.additional_information"
              :options="editorOption"
              @change="quillIndex('7')"
              ref="quillEdit7"
            >
            </quill-editor>
          </b-overlay>
        </b-form-group>
      -->
        <!-- Complementary Material -->
        <h6 class="text-muted">MATERIAL COMPLEMENTAR </h6>
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
        <b-form enctype="multipart/form-data" method="post">
          <b-form-input class="hidden" name="_token" :value="csrf" />
          <!-- Name Material -->
          <b-form-group
            label="Titulo do material:"
            label-for="i-material"
          >
            <b-form-input
              id="i-material"
              placeholder="Ex: Apostila"
              v-model="support_materials.name"
            />
          </b-form-group>
          <!-- Description Material Complementary -->
            <b-form-group
              label="Autor:"
              label-for="i-material-autor"
            >
              <b-form-input
                v-model="support_materials.description"
              />
            </b-form-group>
            <b-form-group
              label="Tipo:"
              label-for="i-material-tipo"
              class="pl-1 pr-1"
            >
            <b-row>
              <b-col lg="1">
                <b-form-checkbox
                  checked="true"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  v-model="fileOrLink"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="PaperclipIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="Share2Icon" />
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col>
                <span class="switch-icon-right">
                  <feather-icon icon="PaperclipIcon" />
                  Arquivo
                </span>
              </b-col>
              <b-col>
                <span class="switch-icon-right">
                  <feather-icon icon="Share2Icon" />
                  Link
                </span>
              </b-col>
            </b-row>
              
            </b-form-group>
          <!-- Upload File Complementary -->
          <b-form-group
            label="Material Complementar:"
            label-for="i-complementar"
          >
            <b-form-file
              v-if="fileOrLink == true"
              id="i-complementar"
              placeholder="Selecione um arquivo"
              v-model="file"
              type="file"
              name="file"
            />
            <b-form-input
              v-if="fileOrLink == false"
              placeholder="Insira o link do material"
              v-model="support_materials.link"
            />
          </b-form-group>
          <b-row>
            <b-col lg="10" sm="10">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-dark"
                block
                @click="storeSupportMaterial"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Incluir Material</span>
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-primary"
                block
                v-b-modal.modal-show-activities
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
              </b-button>
            </b-col>
            
          </b-row>
        </b-form>
        </b-overlay>
        
        <hr>
        <b-row>
          <!-- Add Activity -->
          <b-button v-if="idActivitiesUpdate == null" block v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addLesson" :disabled="!activities.description"> Adicionar</b-button>
          <b-button v-else block v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="editActivity(idActivitiesUpdate)"> Editar</b-button>  
        </b-row>
      </b-col>
      <b-col lg="4" sm="6">
        <h3 class="text-center">Atividades adicionadas</h3>
        <hr>
        <div class="list-activities overflow-auto">
          <p :key="indextr" v-for="(tr, indextr) in activitiesArray">
              <b-row>
                <b-col md="9">
                  <b>{{tr.name_lesson}}</b> | {{tr.description}}
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    @click="editActivityShow(tr.id)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="deleteActivity(tr.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-col>
              </b-row>
          </p>
        </div>
      </b-col>
    </b-row>
    <div>
      <!-- modal Show List-->
      <b-modal
        id="modal-show-activities"
        ref="modal-show-activities"
        cancel-variant="outline-secondary"
        cancel-title="Fechar"
        centered
        title="Materiais adicionados - Atividades"
      >
        <p>
          <b-row :key="indextr" v-for="(tr, indextr) in supportLocalMaterials">
            <b-col md="9">
              {{tr.name}}
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="deleteSupport(tr.id, indextr)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import axios from "@axios";

export default {
  components: {
    BButton,
    BSidebar,
    quillEditor
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      id_lesson: 0,
      dataLesson: null,
      support_materials: [],
      listActivities: [],
      idActivities: 1,
      idActivitiesUpdate: null,
      supportLocalMaterials: [],
      support_materials_id: [],
      file: null,
      id: 1,
      id_activity: Math.floor(Math.random() * 65536),
      watchLesson: null,
      activities: {
        description: null,
        phase_id: null,
        lesson_id: null,
        support_materials: null,
        duration: null,
        general_view: null,
        pedagogical_objectives: null,
        necessary_materials: null,
        preparing_the_activity: null,
        introduction: null,
        development: null,
        additional_information: null
      },

      activitiesArray: [],

      isLoadingStore: false,

      fileOrLink: true,

      optionsQuest: [],
      optionMultiple: null,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      indexQuill: null,

      isLoadingQuill: false,

      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'align': [] }],
              ['image', 'link']
            ],
            handlers: {
              image: function() {
                document.getElementById('file').click()
              }
            }
          }
        }
      },
      content: null,
    }
  },
  props: {
    lessonSelectLoading: {
      default: function() {
        return []
      }
    }
  },
  computed: {
    ...mapState("phases", ["phases"]),
    ...mapState("responseType", ["responseType"]),
    ...mapState("supportMaterials", ["supportMaterials"]),
    lesson(){
      return  localStorage.book ? JSON.parse(localStorage.book) : null 
    }
  },
  created() {
    this.fetchPhases();
    this.fetchResponseType();
    if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
    if(this.lesson){
      this.loadingListActivities();
    }
  },
  methods: {
    ...mapActions("supportMaterials", ["store", "delete"]),

    quillIndex(value){
      this.indexQuill = value
    },

    uploadFunction(e){
      this.isLoadingQuill = true;
      this.selectedFile = e.target.files[0];
      var form = new FormData();
      form.append("image", this.selectedFile);

      axios.post('image-upload', form,{
        'headers': {
          'Content-Type': "multipart/form-data"
        }
      })
      .then(r => {
        if(this.indexQuill == '1'){
          const range = this.$refs.quillEdit1.$refs.editor.__quill.selection.savedRange

          this.$refs.quillEdit1.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        }
        if(this.indexQuill == '2'){
          const range = this.$refs.quillEdit2.$refs.editor.__quill.selection.savedRange

          this.$refs.quillEdit2.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        }
        if(this.indexQuill == '3'){
          const range = this.$refs.quillEdit3.$refs.editor.__quill.selection.savedRange

          this.$refs.quillEdit3.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        }
        if(this.indexQuill == '4'){
          const range = this.$refs.quillEdit4.$refs.editor.__quill.selection.savedRange

          this.$refs.quillEdit4.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        }
        if(this.indexQuill == '5'){
          const range = this.$refs.quillEdit5.$refs.editor.__quill.selection.savedRange

          this.$refs.quillEdit5.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        }
        if(this.indexQuill == '6'){
          const range = this.$refs.quillEdit6.$refs.editor.__quill.selection.savedRange

          this.$refs.quillEdit6.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        }
        if(this.indexQuill == '7'){
          const range = this.$refs.quillEdit7.$refs.editor.__quill.selection.savedRange

          this.$refs.quillEdit7.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        }

        this.isLoadingQuill = false
      })
      .catch(e => {
        this.isLoadingQuill = false
      });
    },

    deleteSupport(id, index) { 
      let data = id
      this.delete(
        data
      )
      .then(() => {
        this.supportLocalMaterials.splice(index, 1)
        this.support_materials_id.splice(index, 1)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{

      });
    },

    editActivityShow(val){
       this.activitiesArray.forEach((value, index) => {
         if(value.id == val){
           this.activities.description = value.description
           this.activities.phase_id = value.phase_id
           this.activities.lesson_id = value.lesson_id

           this.activities.duration = value.duration
           this.activities.general_view = value.general_view
           this.activities.pedagogical_objectives = value.pedagogical_objectives
           this.activities.necessary_materials = value.necessary_materials
           this.activities.preparing_the_activity = value.preparing_the_activity
           this.activities.introduction = value.introduction
           this.activities.development = value.development
           this.activities.additional_information = value.additional_information

           this.idActivitiesUpdate = value.id
         }
       })
    },

    editActivity(val){

      var lessonLoading = JSON.parse(localStorage.book)

      this.activitiesArray.forEach((value, index) => {
        if(value.id == val){
          this.activitiesArray[index].description = this.activities.description
          this.activitiesArray[index].phase_id = this.activities.phase_id
          this.activitiesArray[index].lesson_id = this.activities.lesson_id
          this.activitiesArray[index].response_type_id = this.activities.response_type_id
          this.activitiesArray[index].activity_options = this.activities.activity_options

          this.activitiesArray[index].duration = this.activities.duration
          this.activitiesArray[index].general_view = this.activities.general_view
          this.activitiesArray[index].pedagogical_objectives = this.activities.pedagogical_objectives
          this.activitiesArray[index].necessary_materials = this.activities.necessary_materials
          this.activitiesArray[index].preparing_the_activity = this.activities.preparing_the_activity
          this.activitiesArray[index].introduction = this.activities.introduction
          this.activitiesArray[index].development = this.activities.development
          this.activitiesArray[index].additional_information = this.activities.additional_information
        }
      })

      lessonLoading.lessons.forEach((valueLesson, indexLesson) => {
        lessonLoading.lessons[indexLesson].activities.forEach((valueActivities, indexActivities) => {
          if(parseInt(val) == parseInt(valueActivities.id)){
            localStorage.removeItem(["book"]);
            this.lesson.lessons[indexLesson].activities.splice(indexActivities, 1);
            this.lesson.lessons[indexLesson].activities.push({
              id: Math.floor((Math.random() * 65536) * this.activitiesArray[indexActivities].id),
              description: this.activitiesArray[indexActivities].description,
              phase_id: this.activitiesArray[indexActivities].phase_id,
              lesson_id: this.activitiesArray[indexActivities].lesson_id,
              response_type_id: this.activitiesArray[indexActivities].response_type_id,
              support_materials: this.activitiesArray[indexActivities].support_materials,
              activity_options: this.activitiesArray[indexActivities].activity_options,
              name_lesson: lessonLoading.lessons[indexLesson].name,

              duration: this.activitiesArray[indexActivities].duration,
              general_view: this.activitiesArray[indexActivities].general_view,
              pedagogical_objectives: this.activitiesArray[indexActivities].pedagogical_objectives,
              necessary_materials: this.activitiesArray[indexActivities].necessary_materials,
              preparing_the_activity: this.activitiesArray[indexActivities].preparing_the_activity,
              introduction: this.activitiesArray[indexActivities].introduction,
              development: this.activitiesArray[indexActivities].duration,
              additional_information: this.activitiesArray[indexActivities].additional_information
            })
            localStorage.setItem(
              "book", JSON.stringify(this.lesson)
            )
          }
        })
      })

      this.idActivitiesUpdate = null
      this.activities.description = null
      this.activities.phase_id = null
      this.activities.lesson_id = null

      this.activities.duration = null
      this.activities.general_view = null
      this.activities.pedagogical_objectives = null
      this.activities.necessary_materials = null
      this.activities.preparing_the_activity = null
      this.activities.introduction = null
      this.activities.development = null
      this.activities.additional_information = null

      this.activities.support_materials = []
      this.supportLocalMaterials = []
    },

    deleteActivity(val){
      var lessonLoading = JSON.parse(localStorage.book)
      lessonLoading.lessons.forEach((valueLesson, indexLesson) => {
        lessonLoading.lessons[indexLesson].activities.forEach((valueActivities, indexActivities) => {
          if(parseInt(val) == parseInt(valueActivities.id)){
            this.activitiesArray.forEach((value, index) => {
              if(value.id == val){
                this.activitiesArray.splice(index, 1)
              }
            })
            localStorage.removeItem(["book"]);
            this.lesson.lessons[indexLesson].activities.splice(indexActivities, 1);
            localStorage.setItem(
              "book", JSON.stringify(this.lesson)
            )
          }
        })
      })
    },

    // Loading Phases - Using Select Phases
    fetchPhases(paginated = false){
      this.$store
      .dispatch("phases/fetchPhases", {
        paginate: paginated,
      })
      .then((response) => {
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
      })
    },

    // Store Material Support
    storeSupportMaterial() {
      this.isLoadingStore = true
      this.store({
        ...this.support_materials,
        file: this.file,
      })
      .then((val) => {

        this.support_materials_id.push({
          id: val.id,
        })
         this.supportLocalMaterials.push({
          id: val.id,
          name: val.name,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Material adicionado com sucesso!',
            icon: 'BookIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })
        this.support_materials = []
        this.file = null
      })
      .finally(() =>{
        this.isLoadingStore = false
      });
    },

    // Loading Response Types - Using Select Response Type
    fetchResponseType(){
      this.$store
      .dispatch("responseType/fetchResponseType")
      .then((response) => {
        this.optionsQuest = response;
        this.optionsQuest.forEach((value, index) => {

          if(value.description == "Múltipla escolha"){
            this.optionMultiple = value.id;
          }
        })

        
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
      })
    },

    loadingListActivities(value_id){

      var lessonLoading = JSON.parse(localStorage.book)
      if(value_id){
        lessonLoading.lessons.forEach((valueLesson, indexLesson) => {
          lessonLoading.lessons[indexLesson].activities.forEach((valueActivities, indexActivities) => {
            if(parseInt(value_id) == parseInt(valueActivities.id)){
              this.activitiesArray.push(valueActivities)
            }
          })
        })
      }else{
        lessonLoading.lessons.forEach((valueLesson, indexLesson) => {
          lessonLoading.lessons[indexLesson].activities.forEach((valueActivities, indexActivities) => {
            this.activitiesArray.push(valueActivities)
          })
        })
      }

      this.$emit("activitiesFinish", 
        {
          active: true,
        });
    
    },

    // Store Activity - Save LocalStorage
    addLesson(){

      this.lesson.lessons.forEach((valueLesson, indexLesson) => {

        if(this.lesson.lessons[indexLesson].id == this.activities.lesson_id){
          this.lesson.lessons[indexLesson].activities.push({
            id: this.id_activity,
            description: this.activities.description,
            phase_id: this.activities.phase_id,
            lesson_id: this.activities.lesson_id,
            response_type_id: this.activities.response_type_id,
            support_materials: this.activities.support_materials,
            activity_options: this.activities.activity_options,
            name_lesson: this.lesson.lessons[indexLesson].name,

             duration: this.activities.duration,
            general_view: this.activities.general_view,
            pedagogical_objectives: this.activities.pedagogical_objectives,
            necessary_materials: this.activities.necessary_materials,
            preparing_the_activity: this.activities.preparing_the_activity,
            introduction: this.activities.introduction,
            development: this.activities.development,
            additional_information: this.activities.additional_information
          })
          localStorage.setItem(
            "book", JSON.stringify(this.lesson)
          )
           
        }
      })

      this.loadingListActivities(this.id_activity);

      this.id_activity = Math.floor(Math.random() * 65536);

      // Clear form

      this.activities.description = null 
      this.activities.phase_id = null
      this.activities.lesson_id = null
      this.activities.support_materials = []
      this.supportLocalMaterials = []
      this.activities.duration = null
      this.activities.general_view = null
      this.activities.pedagogical_objectives = null
      this.activities.necessary_materials = null
      this.activities.preparing_the_activity = null
      this.activities.introduction = null
      this.activities.development = null
      this.activities.additional_information = null
    },
  },
  watch: {
    activities(){
      this.watchLesson = JSON.parse(localStorage.book)
    }
  }
}
</script>
<style lang="scss">
</style>