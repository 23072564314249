<template>
  <div>
    <b-sidebar
      id="sidebar-create-class"
      no-close-on-backdrop
      no-close-on-esc
      shadow
      width="750px"
      right
      bg-variant="white"
      backdrop
      title="Cadastrar nova aula"
      ref="sidebar-create-class"
    >
      <div>
        <validation-observer ref="simpleRules">
        <!-- Form create Lesson -->
        <!-- Name -->
        <b-form-group
          label="Titulo da aula:"
          label-for="i-titulo"
          class="pl-1 pr-1"
        >
          <validation-provider
            #default="{ errors }"
            name="titulo"
            rules="required"
          >
            <b-form-input
              id="i-titulo"
              placeholder="Ex: Aula 1 - Estruturas"
              :state="errors.length > 0 ? false:null"
              v-model="lessons.name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Module Id -->
        <b-form-group
          label="Selecione um módulo:"
          label-for="i-modulo"
          class="pl-1 pr-1"
        >
          <validation-provider
            #default="{ errors }"
            name="módulo"
            rules="required"
          >
            <b-form-select
              text-field="name"
              value-field="id"
              v-model="lessons.module_id"
              :options="modules"
              :state="errors.length > 0 ? false:null"
              id="module"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Objective -->
        <b-form-group
          label="Objetivo:"
          label-for="i-objetivo"
          class="pl-1 pr-1"
        >
          <validation-provider
            #default="{ errors }"
            name="objetivo"
            rules="required"
          >
            <b-form-textarea
              id="i-objetivo"
              :state="errors.length > 0 ? false:null"
              placeholder="Informe o objetivo da aula"
              rows="3"
              v-model="lessons.objective"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Competence BNCC -->
        <b-form-group
          label="Competências BNCC:"
          label-for="i-bncc"
          class="pl-1 pr-1"
        >
          <validation-provider
            #default="{ errors }"
            name="BNCC"
            rules="required"
          >
            <v-select
              v-model="lessons.competences"
              size="lg"
              multiple
              label="code"
              :state="errors.length > 0 ? false:null"
              :options="compentences"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Ability -->
        <b-form-group
          label="Habilidades:"
          label-for="i-habilidades"
          class="pl-1 pr-1"
        >
          <validation-provider
            #default="{ errors }"
            name="Habilidade"
            rules="required"
          >
            <v-select
              v-model="lessons.abilities"
              size="lg"
              multiple
              label="code"
              :options="abilities"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        </validation-observer>
        <hr>
        <!-- Complementary Material -->
        <h6 class="pl-1 pr-1 text-muted">MATERIAL COMPLEMENTAR</h6>
        <div>
          <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-form enctype="multipart/form-data" method="post">
            <b-form-input class="hidden" name="_token" :value="csrf" />

            <!-- Name Material -->
            <b-form-group
              label="Titulo do material:"
              label-for="i-material-title"
              class="pl-1 pr-1" 
            >
              <b-form-input
                id="i-material-title"
                placeholder="Ex: Apostila"
                v-model="support_materials.name"
              />
            </b-form-group>
            <!-- Description Material Complementary -->
            <b-form-group
              label="Autor:"
              label-for="i-material-autor"
              class="pl-1 pr-1"
            >
              <b-form-input
                v-model="support_materials.description"
              />
            </b-form-group>

            <b-form-group
              label="Tipo:"
              label-for="i-material-tipo"
              class="pl-1 pr-1"
            >
            <b-row>
              <b-col lg="1">
                <b-form-checkbox
                  checked="true"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  v-model="fileOrLink"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="PaperclipIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="Share2Icon" />
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col>
                <span class="switch-icon-right">
                  <feather-icon icon="PaperclipIcon" />
                  Arquivo
                </span>
              </b-col>
              <b-col>
                <span class="switch-icon-right">
                  <feather-icon icon="Share2Icon" />
                  Link
                </span>
              </b-col>
            </b-row>
              
            </b-form-group>
            <!-- Upload File Complementary -->
            <b-form-group
              label="Material Complementar:"
              label-for="i-material-complementar"
              class="pl-1 pr-1"
            >
              <b-form-file
                v-if="fileOrLink == true"
                placeholder="Selecione um arquivo"
                v-model="file"
                type="file"
                name="file"
              />
              <b-form-input
                v-if="fileOrLink == false"
                placeholder="Insira o link do material"
                v-model="support_materials.link"
              />
            </b-form-group>
            <b-row>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-dark"
                block
                @click="storeSupportMaterial"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Incluir Material</span>
              </b-button>
            </b-row>
            <b-row v-if="lessons.support_materials">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-primary"
                block
                v-b-modal.modal-show-support
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Ver materiais adicionados</span>
              </b-button>
            </b-row>
          </b-form>
          </b-overlay>
          
        </div>
        
      </div>
      <b-row class="p-1">
        <b-col  lg="4" sm="4" class="mr-1">
          <!-- Save -->
          <b-button block variant="relief-primary" @click="storeLesson(value = 1)" :disabled="lessons.name == null">
            Salvar
          </b-button>
        </b-col>
        <b-col>
          <!-- Exit -->
          <b-button 
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="closeLesson()"
            block
          >
            Fechar
          </b-button>
        </b-col>
      </b-row>
    </b-sidebar>
    <div>
      <!-- modal Show List-->
      <b-modal
        id="modal-show-support"
        ref="modal-show-support"
        cancel-variant="outline-secondary"
        cancel-title="Fechar"
        centered
        title="Materiais adicionados"
      >
        <p>
          <b-row :key="indextr" v-for="(tr, indextr) in supportLocalMaterials">
            <b-col md="9">
              {{tr.name}}
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="deleteSupport(tr.id, indextr)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BSidebar,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {

      isLoadingStore: false,

      id: 0,
      id_lesson: Math.floor(Math.random() * 65536),
      id_suport_materials: null,
      supportLocalMaterials: [],
      showSupport: false,
      support_materials: [],
      file: null,
      compentencesOptions: [],

      fileOrLink: true,

      lessons: {
        id: null,
        name: null,
        objective: null,
        module_id: null,
        abilities: [],
        competences: [],
        activities: [],
        support_materials: []
      },
      dataBook: null,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("modules", ["modules"]),
    ...mapState("supportMaterials", ["supportMaterials"]),
    ...mapState("compentences", ["compentences"]),
    ...mapState("abilities", ["abilities"]),
    availableOptions() {
      return this.options.filter(opt => this.value.indexOf(opt) === -1)
    }
  },
  methods: {

    ...mapActions("supportMaterials", ["store", "delete"]),

    closeLesson(){
      this.$refs['sidebar-create-class'].hide()
    },

    // Store Material Support
    storeSupportMaterial() {
      this.isLoadingStore = true
      this.store({
        ...this.support_materials,
        file: this.file,
      })
      .then((val) => {
        this.lessons.support_materials.push({
          id: val.id,
        })
        this.supportLocalMaterials.push({
          id: val.id,
          name: val.name,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Material adicionado com sucesso!',
            icon: 'BookIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })

        this.support_materials = []
        this.file = null
      })
      .finally(() =>{
        this.isLoadingStore = false
      });
    },

    // Delete Material Support
    deleteSupport(id, index) { 
      let data = id
      this.delete(
        data
      )
      .then(() => {
        this.supportLocalMaterials.splice(index, 1)
        this.lessons.support_materials.splice(index, 1)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{

      });
    },

    // Loading Compentence - Using Select Compentence
    fetchCompentences() {
      this.$store
        .dispatch("compentences/fetchCompentence", {
          simple_list: true
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    // Loading Abilities - Using Select Ability
    fetchAbility() {
      this.$store
        .dispatch("abilities/fetchAbility", {
          simple_list: true
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    // Loading Modules - Using Select Modules
    fetchModules(paginated = false) {
      this.isLoading = true;
      this.$store
        .dispatch("modules/fetchModules", {
          paginate: paginated,
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    // Store Lesson -  Save LocalStorage
    storeLesson(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataBook = JSON.parse(localStorage.book)

          var positionArray = parseInt(this.dataBook.lessons.length);

          if(!this.dataBook.lessons[positionArray]){
            this.dataBook.lessons[positionArray] = this.lessons
            this.dataBook.lessons[positionArray].id = this.id_lesson
            localStorage.setItem(
              "book", JSON.stringify(this.dataBook)
            )
          }else{
            positionArray++
            this.id_lesson = Math.floor((Math.random() * 65536) * this.id_lesson)
            this.dataBook.lessons[positionArray] = this.lessons
            this.dataBook.lessons[positionArray].id = this.id_lesson
            localStorage.setItem(
              "book", JSON.stringify(this.dataBook)
            )
          }

          this.id_lesson = Math.floor((Math.random() * 65536) * this.id_lesson)
          // Loading table
          this.$emit("loadingTable", 
          {
            active: true,
          });
          // Close Sidebar
          if(value == 1){
            this.$refs['sidebar-create-class'].hide()
          } 
          // Clear Form
          this.lessons.id = null
          this.lessons.name = null
          this.lessons.objective = null
          this.lessons.module_id = null
          this.lessons.abilities = []
          this.lessons.competences = []
          this.lessons.activities = []
          this.lessons.support_materials = []
          this.support_materials = []
          this.supportLocalMaterials = []
          this.$nextTick(() => this.$refs.simpleRules.reset());
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
      
    },
  },
  created() {
     this.fetchModules();
     this.fetchCompentences();
     this.fetchAbility();
     if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
  watch: {
  }
}
</script>
<style scoped>
  .margin-tag{
    margin-bottom: 1px !important;
    margin-right: 1px !important;
  }
</style>