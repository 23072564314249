<template>
  <div class="mb-1">
    <h3>Adicione suas atividades</h3>
    <small class="text-muted">Preencha abaixo as informações da sua atividade, quando terminar, clique em finalizar</small>
    <hr>
    <!-- Form Activity -->
    <b-row class="mx-0">
      <b-col  lg="8" sm="6">
        <validation-observer ref="simpleRules">
        <!-- Description -->
        <!--<b-form-group
          label="Pergunta:"
          label-for="i-pergunta"
        >
          <validation-provider
            #default="{ errors }"
            name="titulo"
            rules="required"
          >
            <b-form-input
              id="i-pergunta"
              placeholder="Digite sua pergunta"
              v-model="activities.description"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>-->
        <b-form-group
          label="Descrição:"
        >
          <input type="file" @change="uploadFunction" id="file" hidden />
          <b-overlay
            :show="isLoadingQuill"
            opacity="0.70"
            variant="transparent"
            rounded="sm"
          >
            <quill-editor
              v-model="activities.description"
              :options="editorOption"
              ref="quillEdit"
            >
            </quill-editor>
          </b-overlay>
        </b-form-group>
        <!-- Lesson Id -->
        <b-form-group
          label="Selecione a aula:"
          label-for="i-aula"
        >
          <b-form-select
            text-field="name"
            value-field="id"
            v-model="activities.lesson_id"
            :options="lessonSelectLoading.lessons"
            id="module"
          />
        </b-form-group>
        <!-- Phases Id -->
        <b-form-group
          label="Selecione a fase da espiral:"
          label-for="i-phase"
        >
          <b-form-select
            text-field="name"
            value-field="id"
            v-model="activities.phase_id"
            :options="phases"
            id="module"
          />
        </b-form-group>
        <!-- Response Type Id -->
        <b-form-group
          label="Selecione o tipo da questão:"
          label-for="i-quest"
        >
          <b-form-select
            text-field="description"
            value-field="id"
            v-model="activities.response_type_id"
            :options="optionsQuest"
            @change="optionsResponse"
          />
        </b-form-group>
        </validation-observer>
        <!-- Form Multiple choices -->
        <div v-if="activities.response_type_id == optionMultiple || activities.response_type_id == optionTrueOrFalse">
          <!-- Copyable Line -->
          <b-row v-for="(item, index) in activities.activity_options" :id="item.id" :key="item.id">
            <b-col md="6">
              <!-- Description Multiple -->
              <b-form-group
                label='Alternativa'
                label-for="item-name"
              >
                <b-form-input
                  id="item-name"
                  type="text"
                  placeholder="Alternativa"
                  v-model="activities.activity_options[index].description"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <!-- Is Correct -->
              <b-form-group
                label='Essa opção é a correta?'
                label-for="item-name"
              >
                <b-form-checkbox
                  checked="false"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  v-model="activities.activity_options[index].is_correct"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- Delete Line -->
            <b-col v-if="index != 0">
              <b-button 
                @click="removeInput(index)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
              >
                <feather-icon icon="XIcon" /> Apagar
              </b-button>
            </b-col>
          </b-row>

          <!-- Add Line -->
          <b-row>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-dark"
              @click=" addInput"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Adicionar Alternativa</span>
            </b-button>
          </b-row>
        </div>
        <!-- Complementary Material -->
        <h6 class="text-muted">MATERIAL COMPLEMENTAR </h6>
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
        <b-form enctype="multipart/form-data" method="post">
          <b-form-input class="hidden" name="_token" :value="csrf" />
          <!-- Name Material -->
          <validation-observer ref="simpleRules">
            <b-form-group
              label="Titulo do material:"
              label-for="i-material"
            >
              <validation-provider
                #default="{ errors }"
                name="titulo"
                rules="required"
              >
                <b-form-input
                  id="i-material"
                  placeholder="Ex: Apostila"
                  v-model="support_materials.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Description Material Complementary -->
            <b-form-group
              label="Autor:"
              label-for="i-material-autor"
            >
              <b-form-input
                v-model="support_materials.description"
              />
            </b-form-group>
            <b-form-group
              label="Tipo:"
              label-for="i-material-tipo"
              class="pl-1 pr-1"
            >
            <b-row>
              <b-col lg="1">
                <b-form-checkbox
                  checked="true"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  v-model="fileOrLink"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="PaperclipIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="Share2Icon" />
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col>
                <span class="switch-icon-right">
                  <feather-icon icon="PaperclipIcon" />
                  Arquivo
                </span>
              </b-col>
              <b-col>
                <span class="switch-icon-right">
                  <feather-icon icon="Share2Icon" />
                  Link
                </span>
              </b-col>
            </b-row>
              
            </b-form-group>
          <!-- Upload File Complementary -->
          <b-form-group
            label="Material Complementar:"
            label-for="i-complementar"
          >        

            <b-form-file
              v-if="fileOrLink == true"
              id="i-complementar"
              placeholder="Selecione um arquivo"
              v-model="file"
              type="file"
              name="file"
            />
            <b-form-input
              v-if="fileOrLink == false"
              placeholder="Insira o link do material"
              v-model="support_materials.link"
            />
          </b-form-group>
          <b-row>
            <b-col lg="10" sm="10">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-dark"
                block
                @click="storeSupportMaterial"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Incluir Material</span>
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-primary"
                block
                v-b-modal.modal-show-activities
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
              </b-button>
            </b-col>
            
          </b-row>
          </validation-observer>

        </b-form>
        </b-overlay>
        
        <hr>
        <b-row>
          <!-- Add Activity -->
          <b-button v-if="idActivitiesUpdate == null" block v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addLesson" :disabled="!activities.description"> Adicionar</b-button>
          <b-button v-else block v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="editActivity(idActivitiesUpdate)"> Editar</b-button>  
        </b-row>
      </b-col>
      <b-col lg="4" sm="6">
        <h3 class="text-center">Atividades adicionadas</h3>
        <hr>
        <div class="list-activities overflow-auto">
          <p :key="indextr" v-for="(tr, indextr) in activitiesArray">
              <b-row>
                <b-col md="9">
                  <b>{{tr.name_lesson}}</b> | {{tr.description}}
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-dark"
                    class="btn-icon rounded-circle"
                    @click="editActivityShow(tr.id)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="deleteActivity(tr.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-col>
              </b-row>
          </p>
        </div>
      </b-col>
    </b-row>
    <div>
      <!-- modal Show List-->
      <b-modal
        id="modal-show-activities"
        ref="modal-show-activities"
        cancel-variant="outline-secondary"
        cancel-title="Fechar"
        centered
        title="Materiais adicionados - Atividades"
      >
        <p>
          <b-row :key="indextr" v-for="(tr, indextr) in supportLocalMaterials">
            <b-col md="9">
              {{tr.name}}
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="deleteSupport(tr.id, indextr)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import axios from "@axios";

export default {
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    quillEditor
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      id_lesson: 0,
      dataLesson: null,
      support_materials: [],
      listActivities: [],
      idActivities: 1,
      idActivitiesUpdate: null,
      supportLocalMaterials: [],
      support_materials_id: [],
      file: null,
      id: 1,
      id_activity: Math.floor(Math.random() * 65536),
      watchLesson: null,
      activities: {
        note: null,
        activity_options: [],
        description: null,
        phase_id: null,
        lesson_id: null,
        response_type_id: 'caa6b7a5-521c-4b0c-abf1-cafdef306732',
        support_materials: null,
      },

      activitiesArray: [],

      isLoadingStore: false,

      optionsQuest: [],
      optionMultiple: null,
      optionTrueOrFalse: null,

      fileOrLink: true,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      isLoadingQuill: false,

      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'align': [] }],
              ['image', 'link'],
            ],
            handlers: {
              image: function() {
                document.getElementById('file').click()
              }
            }
          }
        }
      },
    }
  },
  props: {
    lessonSelectLoading: {
      default: function() {
        return []
      }
    }
  },
  computed: {
    ...mapState("phases", ["phases"]),
    ...mapState("responseType", ["responseType"]),
    ...mapState("supportMaterials", ["supportMaterials"]),
    lesson(){
      return JSON.parse(localStorage.book)
    }
  },
  created() {
    this.fetchPhases();
    this.fetchResponseType();
    if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
    if(this.lesson){
      this.loadingListActivities();
    }
  },
  methods: {
    ...mapActions("supportMaterials", ["store", "delete"]),

    uploadFunction(e){
      this.isLoadingQuill = true;
      this.selectedFile = e.target.files[0];
      var form = new FormData();
      form.append("image", this.selectedFile);

      axios.post('image-upload', form,{
        'headers': {
          'Content-Type': "multipart/form-data"
        }
      })
      .then(r => {

        const range = this.$refs.quillEdit.$refs.editor.__quill.selection.savedRange

        this.$refs.quillEdit.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        this.isLoadingQuill = false
      })
      .catch(e => {
        this.isLoadingQuill = false
      });
    },

    optionsResponse(value){
      if(value != this.optionMultiple && value != this.optionTrueOrFalse){
        this.activities.activity_options = []
      }
    },

    deleteSupport(id, index) { 
      let data = id
      this.delete(
        data
      )
      .then(() => {
        this.supportLocalMaterials.splice(index, 1)
        this.support_materials_id.splice(index, 1)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{

      });
    },

    editActivityShow(val){
       this.activitiesArray.forEach((value, index) => {
         if(value.id == val){
           this.note = value.note,
           this.activities.description = value.description
           this.activities.phase_id = value.phase_id
           this.activities.lesson_id = value.lesson_id
           this.activities.response_type_id = value.response_type_id
           this.idActivitiesUpdate = value.id
          this.activities.activity_options = value.activity_options;
         }
       })
    },

    editActivity(val){
      var lessonLoading = JSON.parse(localStorage.book)

      this.activitiesArray.forEach((value, index) => {
        if(value.id == val){
          this.activitiesArray[index].note = this.activities.note
          this.activitiesArray[index].description = this.activities.description
          this.activitiesArray[index].phase_id = this.activities.phase_id
          this.activitiesArray[index].lesson_id = this.activities.lesson_id
          this.activitiesArray[index].response_type_id = this.activities.response_type_id
          this.activitiesArray[index].activity_options = this.activities.activity_options
        }
      })

      lessonLoading.lessons.forEach((valueLesson, indexLesson) => {
        lessonLoading.lessons[indexLesson].activities.forEach((valueActivities, indexActivities) => {
          if(parseInt(val) == parseInt(valueActivities.id)){
            localStorage.removeItem(["book"]);
            this.lesson.lessons[indexLesson].activities.splice(indexActivities, 1);
            this.lesson.lessons[indexLesson].activities.push({
              id: Math.floor((Math.random() * 65536) * this.activitiesArray[indexActivities].id),
              note: this.activitiesArray[indexActivities].note,
              description: this.activitiesArray[indexActivities].description,
              phase_id: this.activitiesArray[indexActivities].phase_id,
              lesson_id: this.activitiesArray[indexActivities].lesson_id,
              response_type_id: this.activitiesArray[indexActivities].response_type_id,
              support_materials: this.activitiesArray[indexActivities].support_materials,
              activity_options: this.activitiesArray[indexActivities].activity_options,
              name_lesson: lessonLoading.lessons[indexLesson].name,
            })
            localStorage.setItem(
              "book", JSON.stringify(this.lesson)
            )
          }
        })
      })

      this.idActivitiesUpdate = null
      this.activities.activity_options = []
      this.activities.note = null
      this.activities.description = null
      this.activities.phase_id = null
      this.activities.lesson_id = null
      this.activities.response_type_id = 'caa6b7a5-521c-4b0c-abf1-cafdef306732'
      this.activities.support_materials = []
      this.supportLocalMaterials = []
      this.$nextTick(() => this.$refs.simpleRules.reset());
    },

    deleteActivity(val){
      var lessonLoading = JSON.parse(localStorage.book)
      lessonLoading.lessons.forEach((valueLesson, indexLesson) => {
        lessonLoading.lessons[indexLesson].activities.forEach((valueActivities, indexActivities) => {
          if(parseInt(val) == parseInt(valueActivities.id)){
            this.activitiesArray.forEach((value, index) => {
              if(value.id == val){
                this.activitiesArray.splice(index, 1)
              }
            })
            localStorage.removeItem(["book"]);
            this.lesson.lessons[indexLesson].activities.splice(indexActivities, 1);
            localStorage.setItem(
              "book", JSON.stringify(this.lesson)
            )
          }
        })
      })
    },

    // confirmActivity(){
    //   this.activitiesArray.forEach((value, index) => {
    //      this.lesson.lessons.forEach((valueLesson, indexLesson) => {
    //       if(this.lesson.lessons[indexLesson].name == this.activitiesArray[index].name_lesson){
    //         // this.activitiesArray[index]
    //         this.lesson.lessons[indexLesson].activities.push({
    //           description: this.activitiesArray[index].description,
    //           phase_id: this.activitiesArray[index].phase_id,
    //           lesson_id: this.activitiesArray[index].lesson_id,
    //           response_type_id: this.activitiesArray[index].response_type_id,
    //           support_materials: this.activitiesArray[index].support_materials,
    //           activity_options: this.activitiesArray[index].activity_options

    //         })
    //         localStorage.setItem(
    //           "book", JSON.stringify(this.lesson)
    //         )
    //       }
    //     })
    //   })
    //    this.$emit("activitiesFinish", 
    //   {
    //     active: true,
    //   });
    // },

    // Loading Phases - Using Select Phases
    fetchPhases(paginated = false){
      this.$store
      .dispatch("phases/fetchPhases", {
        paginate: paginated,
      })
      .then((response) => {
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
      })
    },

    // Store Material Support
    storeSupportMaterial() {
      this.isLoadingStore = true
      this.store({
        ...this.support_materials,
        file: this.file,
      })
      .then((val) => {

        this.support_materials_id.push({
          id: val.id,
        })
         this.supportLocalMaterials.push({
          id: val.id,
          name: val.name,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Material adicionado com sucesso!',
            icon: 'BookIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })
        this.support_materials = []
        this.file = null
      })
      .finally(() =>{
        this.isLoadingStore = false
      });
    },

    // Loading Response Types - Using Select Response Type
    fetchResponseType(){
      this.$store
      .dispatch("responseType/fetchResponseType")
      .then((response) => {
        this.optionsQuest = response;
        this.optionsQuest.forEach((value, index) => {

          if(value.description == "Múltipla escolha"){
            this.optionMultiple = value.id;
          }
          if(value.description == "Verdadeiro ou Falso"){
            this.optionTrueOrFalse = value.id;
          }
        })

        
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
      })
    },

    loadingListActivities(value_id){

    var lessonLoading = JSON.parse(localStorage.book)
    if(value_id){
      lessonLoading.lessons.forEach((valueLesson, indexLesson) => {
        lessonLoading.lessons[indexLesson].activities.forEach((valueActivities, indexActivities) => {
          if(parseInt(value_id) == parseInt(valueActivities.id)){
            this.activitiesArray.push(valueActivities)
          }
        })
      })
    }else{
      lessonLoading.lessons.forEach((valueLesson, indexLesson) => {
        lessonLoading.lessons[indexLesson].activities.forEach((valueActivities, indexActivities) => {
          this.activitiesArray.push(valueActivities)
        })
      })
    }

    this.$emit("activitiesFinish", 
      {
        active: true,
      });
    
    },

    // Store Activity - Save LocalStorage
    addLesson(){
      this.lesson.lessons.forEach((valueLesson, indexLesson) => {

        if(this.lesson.lessons[indexLesson].id == this.activities.lesson_id){
          this.lesson.lessons[indexLesson].activities.push({
            id: this.id_activity,
            note: this.activities.note,
            description: this.activities.description,
            phase_id: this.activities.phase_id,
            lesson_id: this.activities.lesson_id,
            response_type_id: this.activities.response_type_id,
            support_materials: this.activities.support_materials,
            activity_options: this.activities.activity_options,
            name_lesson: this.lesson.lessons[indexLesson].name,
          })
          localStorage.setItem(
            "book", JSON.stringify(this.lesson)
          )
           
        }
      })

      this.loadingListActivities(this.id_activity);

      this.id_activity = Math.floor(Math.random() * 65536);

      

      // Clear form
      this.activities.activity_options = []
      this.activities.description = null 
      this.activities.note = null 
      this.activities.phase_id = null
      this.activities.lesson_id = null
      this.activities.response_type_id = 'caa6b7a5-521c-4b0c-abf1-cafdef306732'
      this.activities.support_materials = []
      this.supportLocalMaterials = []
      this.$nextTick(() => this.$refs.simpleRules.reset());
    },

    // Add Input - Multiple Choices
    addInput(){
      if(!this.activities.activity_options){
        this.activities.activity_options.push({
          id: 1,
          description: null,
          is_correct: false
        })
      }else{
         this.activities.activity_options.push({
          id: this.id += this.id,
          description: null,
          is_correct: false,
        })
      }
    },

    // Remove Input - Multiple Choices
    removeInput(value){
      this.activities.activity_options.splice(value, 1)
    },
  },
  watch: {
    activities(){
      this.watchLesson = JSON.parse(localStorage.book)
    }
  }
}
</script>
<style lang="scss">
  .list-activities{
    height: 580px;
  }
</style>