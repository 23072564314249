<template>
  <div>
    <b-overlay :show="isLoadingBook" opacity="0.70" variant="transparent" rounded="sm">
    <form-wizard
      color="#1a5f9a"
      :title="null"
      :subtitle="null"
      finish-button-text="Cadastrar"
      back-button-text="Voltar"
      next-button-text="Proximo"
      class="steps-transparent mb-3"
    >
      <!-- Tab Book - First Tab -->
      <tab-content
        title="Livro"
        icon="feather icon-book"
        active
      >
      <!-- Form create book -->
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
          <b-row>
            <b-col md="10">
              <h5 class="mb-0">
                Informações gerais do livro
              </h5>
              <small class="text-muted">
                Informações para identificação do livro
              </small>
            </b-col>
            <b-col>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" @click="backBook" block size="sm"><feather-icon icon="XIcon" /> Sair</b-button>
            </b-col>
          </b-row>
          </b-col>
          <b-col>
            <validation-observer ref="simpleRules">
            <!-- Titulo -->
            <b-form-group
              label="Titulo:"
              label-for="i-titulo"
            >
              <validation-provider
                #default="{ errors }"
                name="titulo"
                rules="required"
              >
                <b-form-input
                  id="i-titulo"
                  placeholder="Ex: 9º Ano"
                  v-model="book.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Categorias -->
            <b-form-group
              label="Categorias:"
              label-for="i-titulo"
            >
              <b-form-select
                v-model="book.type"
                :options="optionsCategories"
              />
            </b-form-group> 
            <!-- Autores -->
            <b-form-group
              label="Autores:"
              label-for="i-autores"
            >
              <validation-provider
                #default="{ errors }"
                name="autores"
                rules="required"
              >
                <b-form-input
                  id="i-autores"
                  placeholder="Ex: Nomes dos autores"
                  v-model="book.author"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Data de publicação -->
            <b-form-group
              label="Data de publicação:"
              label-for="i-data-public"
            >
              <validation-provider
                #default="{ errors }"
                name="data de publicação"
                rules="required"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  placeholder="Data de publicação"
                  class="mb-1"
                  v-model="book.publication_date"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Edição -->
            <b-form-group
              label="Edição:"
              label-for="i-edicao"
            >
              <validation-provider
                #default="{ errors }"
                name="edição"
                rules="required"
              >
                <b-form-input
                  id="i-edicao"
                  placeholder="Ex: 1º Edição"
                  v-model="book.edition"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- ISBN -->
            <b-form-group
              label="ISBN:"
              label-for="i-isbn"
            >
              <validation-provider
                #default="{ errors }"
                name="isbn"
                rules="required"
              >
                <b-form-input
                  id="i-isbn"
                  placeholder="Ex: 978-65-81751-04-3"
                  v-model="book.isbn"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </validation-observer>
          </b-col>
        </b-row>
      <!-- End form create book -->
      </tab-content>

      <!-- Tab Lesson - Second Tab -->
      <tab-content
        title="Aulas"
        icon="feather icon-edit-2"
      >
      <!-- Header Table -->
        <b-row>
          <b-col lg="7" sm="6">
            <b-row>
              <b-col lg="2" sm="6">
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="3" sm="6">
          </b-col>
          <b-col lg="2" sm="6">
            <!-- Open Sidebar - Create Lesson -->
            <b-button variant="relief-primary" class="btn-block" v-b-toggle.sidebar-create-class v-if="lesson && lesson.lessons.length > 0">
              Nova Aula
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </b-col>
        </b-row>
        <!-- End Header Table -->
        <!-- Table Lesson -->
        <b-table-simple
          striped
          stacked="md"
          class="mt-2 responsive text-center"
          id="tablePhases"
          v-if="lesson && lesson.lessons.length > 0"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>Nome</b-th>
              <b-th>Objetivo</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in lesson.lessons">
              <b-td>
                {{ tr.name }}
              </b-td>
              <b-td>
                {{tr.objective}}
              </b-td>
              <b-td>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  v-b-toggle.sidebar-update-class
                  @click="showUpdate(tr.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="showDelete(tr.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>

              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <!-- End Table Lesson -->
        <!-- Don't exist lesson -->
        <div class="text-center" v-show="lesson && lesson.lessons.length == 0" v-else>
          <div v-show="errorStatus == false">
            <h2>
              Nenhuma aula foi encontrada! 📒
            </h2>
            <p>
              <small class="text-muted">Comece adicionando novas aulas ao seu livro!</small>
            </p>
            <b-button variant="relief-primary" v-b-toggle.sidebar-create-class>
              Nova Aula
              <feather-icon icon="FileTextIcon" />
            </b-button>
            <b-button class='ml-1' variant="relief-primary" v-b-toggle.sidebar-select-lessons>
              Selecionar aulas existentes
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </div>
        </div>
        <div v-show="errorStatus == true" class="text-center">
          <h2>
            Preencha os dados do livro antes de prosseguir! 📒
          </h2>
          <small class="text-muted">Volte na tela anterior e preencha corretamente o livro!</small>
        </div>
        <!-- End of warning -->
        <!-- Sidebar update clas -->
        <b-sidebar
          id="sidebar-update-class"
          ref="sidebar-update-class"
          no-close-on-backdrop
          no-close-on-esc
          shadow
          right
          bg-variant="white"
          backdrop
          title="Editar aula"
        >
          <div>
            <b-form-group
              label="Titulo da aula:"
              label-for="i-titulo"
              class="pl-1 pr-1"
            >
              <b-form-input
                id="i-titulo"
                v-model="updateLesson.name"
                placeholder="Ex: Aula 1 - Estruturas"
              />
            </b-form-group>
            <!-- Module Id -->
            <b-form-group
              label="Selecione um módulo:"
              label-for="i-modulo"
              class="pl-1 pr-1"
            >
              <b-form-select
                text-field="name"
                value-field="id"
                v-model="updateLesson.module_id"
                :options="modules"
                id="module"
              />
            </b-form-group>
            <!-- Objective -->
            <b-form-group
              label="Objetivo:"
              label-for="i-objetivo"
              class="pl-1 pr-1"
            >
              <b-form-textarea
                id="i-objetivo"
                placeholder="Informe o objetivo da aula"
                rows="3"
                v-model="updateLesson.objective"
              />
            </b-form-group>
            <!-- Competence BNCC -->
            <b-form-group
              label="Competências BNCC:"
              label-for="i-bncc"
              class="pl-1 pr-1"
            >
              <v-select
                size="lg"
                multiple
                label="code"
                v-model="updateLesson.competences"
                :options="compentences"
              />
            </b-form-group>
            <!-- Ability -->
            <b-form-group
              label="Habilidades:"
              label-for="i-habilidades"
              class="pl-1 pr-1"
            >
              <v-select
                size="lg"
                multiple
                label="code"
                v-model="updateLesson.abilities"
                :options="abilities"
              />
            </b-form-group>
            <hr>
          </div>
          <b-row class="p-1">
            <b-col class="">
              <!-- Save -->
              <b-button block variant="relief-primary" @click="updateLessons">
                Atualizar
              </b-button>
            </b-col>
          </b-row>
        </b-sidebar>
        <!-- Component Sidebar create -->
        <create-edit-class @loadingTable="lessonLoading" />
        <select-lessons @loadingTable="lessonLoading" />

        <b-modal
          id="modal-delete-lesson"
          ref="modal-delete-lesson"
          cancel-variant="outline-secondary"
          cancel-title="Fechar"
          centered
          title="Apagar"
          modal-class="modal-danger"
        >
          <h5 class="text-center"><b>Atenção!</b> Você está preste a deletar esta aula.</h5>
          <template #modal-footer>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mt-2"
                variant="outline-danger"
                block
                @click="deleteLesson"
              >
                Deletar
              </b-button>
            </b-col>
          </template>
        </b-modal>
      </tab-content>

      <!-- Tab Activity - Third Table -->
      <tab-content 
        title="Atividades"
        icon="feather icon-check-square"
      >
        <!-- Component Sidebar create and edit -->
        <create-edit-activity @activitiesFinish="ActivityStoreConfirm" :key="componentKey" :lessonSelectLoading="lessons" v-if="book.type == 'student' "/>

        <create-activity-teacher @activitiesFinish="ActivityStoreConfirm" :key="componentKey" :lessonSelectLoading="lessons" v-else />
      </tab-content>

        <!-- Footer Form -->
      <b-button slot="prev" @click="LoadingForm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"><feather-icon icon="ChevronLeftIcon" /> Voltar</b-button>
      <b-button slot="next" @click="FormSave" v-ripple.400="'rgba(255, 255, 255, 0.15)'"  variant="primary" :disabled='book.name == true'>Próximo <feather-icon icon="ChevronRightIcon" /></b-button>
      <b-button slot="finish" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" :disabled="statusActivity == false" @click="formSubmitted">Cadastrar <feather-icon icon="CheckIcon" /></b-button>
    </form-wizard>
    </b-overlay>

  </div>
</template>

<script>
/* eslint-disable */
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from "vue-ripple-directive";
import CreateEditClass from "./CreateEditClass.vue";
import SelectLessons from "./SelectLessons.vue";
import CreateEditActivity from "./CreateEditActivity.vue";
import CreateActivityTeacher from "./CreateActivityTeacher.vue";
import { heightTransition } from '@core/mixins/ui/transition'
import { mapActions, mapState } from "vuex";
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

require('@core/assets/fonts/feather/iconfont.css')
export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    CreateEditClass,
    SelectLessons,
    CreateEditActivity,
    CreateActivityTeacher,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      dataBook: null,
      lessonData: null,
      bookComplete: null,
      lesson: null,
      statusActivity: false,
      lessonUpdateData: null,
      lessonDeleteData: null,
      lessonDeleteId: null,
      isLoadingBook: false,
      disabledBook: false,
      componentKey: 1,
      updateLesson: {
        id: null,
        name: null,
        objective: null,
        module_id: null,
        abilities: [],
        competences: [],
      },
      book: {
        name: null,
        author: null,
        type: 'student',
        publication_date: null,
        edition: null,
        isbn: null,
        lessons: [],
      },

      errorStatus: false,

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      selectedCategories: 1,
      optionsCategories: [
        { value: 'student', text: 'Aluno' },
        { value: 'teacher', text: 'Professor' }
      ],
    }
  },
  computed: {
    // Loading Lesson - Using in lesson tbale and form book
    lessons(){
      this.lesson = localStorage.book ? JSON.parse(localStorage.book) : null 
      
      if(this.lesson != null){
        this.lessonLoading()
        return JSON.parse(localStorage.book)
      }else{
        return null
      }
      
    },
    ...mapState("compentences", ["compentences"]),
    ...mapState("abilities", ["abilities"]),
    ...mapState("books", ["books"]),
    ...mapState("modules", ["modules"]),
  },
   async mounted() {
    this.lessonData = localStorage.book ? JSON.parse(localStorage.book) : null 
     this.lessonLoading()
      // this.lesson = JSON.parse(localStorage.book)
      if(this.lessonData){
          this.book.name = this.lessonData.name
          this.book.author = this.lessonData.author
          this.book.type = this.lessonData.type
          this.book.publication_date = this.lessonData.publication_date
          this.book.edition = this.lessonData.edition
          this.book.isbn = this.lessonData.isbn
      }else{
        return null
      }
  },
  methods: {
    ...mapActions("books", ["store"]),

    showUpdate(id){
      this.lessonUpdateData = JSON.parse(localStorage.book)
      this.lessonUpdateData.lessons.forEach((value, index) => {
        if(this.lessonUpdateData.lessons[index].id == id){
          this.updateLesson.id = this.lessonUpdateData.lessons[index].id
          this.updateLesson.name = this.lessonUpdateData.lessons[index].name
          this.updateLesson.objective = this.lessonUpdateData.lessons[index].objective
          this.updateLesson.module_id = this.lessonUpdateData.lessons[index].module_id
          this.updateLesson.abilities = this.lessonUpdateData.lessons[index].abilities
          this.updateLesson.competences = this.lessonUpdateData.lessons[index].competences
        }
      })
    },

    updateLessons(){
      this.lessonUpdateData = JSON.parse(localStorage.book)
      this.lessonUpdateData.lessons.forEach((value, index) => {
        if(this.lessonUpdateData.lessons[index].id == this.updateLesson.id){
          this.lessonUpdateData.lessons[index].name = this.updateLesson.name
          this.lessonUpdateData.lessons[index].objective = this.updateLesson.objective
          this.lessonUpdateData.lessons[index].module_id = this.updateLesson.module_id
          this.lessonUpdateData.lessons[index].abilities = this.updateLesson.abilities
          this.lessonUpdateData.lessons[index].competences = this.updateLesson.competences

          localStorage.setItem(
            "book", JSON.stringify(this.lessonUpdateData)
          )

          this.lessonLoading()
        }
      })
    },

    showDelete(id){
      this.lessonDeleteId = id
      this.$refs['modal-delete-lesson'].show()
    },

    deleteLesson(){
      this.lessonDeleteData = JSON.parse(localStorage.book)
      this.lessonDeleteData.lessons.forEach((value, index) => {
        if(this.lessonDeleteData.lessons[index].id == this.lessonDeleteId){
          this.lessonDeleteData.lessons.splice(index, 1)

           localStorage.setItem(
            "book", JSON.stringify(this.lessonDeleteData)
          )
          this.$refs['modal-delete-lesson'].hide()

          this.lessonLoading()
        }
      })
    },

    // Finish form
    formSubmitted() {
      this.isLoadingBook = true
      this.bookComplete = JSON.parse(localStorage.book)
      this.store({
        ...this.bookComplete
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })
        localStorage.removeItem(["book"]);
        this.$router.push("/livros")
      })
      .finally(() =>{
        this.isLoadingBook = false
      });
    
    },

     handlePageChange(value) {
    },

    // Loading Modules - Using Select Modules
    fetchModules(paginated = false) {
      this.$store
        .dispatch("modules/fetchModules", {
          paginate: paginated,
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    // Loading Compentence - Using Select Compentence
    fetchCompentences() {
      this.$store
        .dispatch("compentences/fetchCompentence", {
          simple_list: true
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    // Loading Abilities - Using Select Ability
    fetchAbility() {
      this.$store
        .dispatch("abilities/fetchAbility", {
          simple_list: true
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    ActivityStoreConfirm(val){
      this.statusActivity =  true
    },
    
    // Save form book data
    FormSave(){
       this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.errorStatus = false;
          this.dataBook = JSON.stringify(localStorage.book)
          if(!this.dataBook){

            localStorage.setItem(
              "book", JSON.stringify(this.book)
            )
          }else{
            let bookData = {
              name: this.book.name,
              author: this.book.author,
              type: this.book.type,
              publication_date: this.book.publication_date,
              edition: this.book.edition,
              isbn: this.book.isbn,
              lessons: []
            }
            this.lesson.lessons.forEach((value, index) => {
              bookData.lessons.push(value)
            })
            localStorage.setItem(
              "book", JSON.stringify(bookData)
            )
          }

          this.lessonLoading();
        }else{
          this.errorStatus = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
      
      
    },

    backBook(){
      localStorage.removeItem(["book"]);
      this.$router.push("/livros")
    },

    // Loading book data
    LoadingForm(){
      this.dataBook = JSON.parse(localStorage.book)
      this.book.name = JSON.parse(this.book.name)
      this.book.author = JSON.parse(this.book.author)
      this.book.type = JSON.parse(this.book.type)
      this.book.publication_date = JSON.parse(this.book.publication_date)
      this.book.edition = JSON.parse(this.book.edition)
      this.book.isbn = JSON.parse(this.book.isbn)
    },

    // Loading table lesson
    lessonLoading(){
      this.lesson = localStorage.book ? JSON.parse(localStorage.book) : null 
      this.lessonData = localStorage.book ? JSON.parse(localStorage.book) : null 
      this.componentKey += 1
    },
    
  },
  created(){
    this.fetchCompentences();
    this.fetchAbility();
    this.fetchModules();
  },
  watch: {
    lesson(){

    }
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/base/plugins/forms/form-wizard.scss';
  .btn-block{
    width:100%;
  }
  .repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
